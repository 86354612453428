import './bootstrap';
import '../../vendor/masmerise/livewire-toaster/resources/js';
// import Alpine from 'alpinejs'
// window.Alpine = Alpine
// Alpine.start()

import Alpine from 'alpinejs'
import mask from '@alpinejs/mask'
Alpine.plugin(mask)

import '@nextapps-be/livewire-sortablejs';

import { Carousel, initTE } from "tw-elements";
initTE({ Carousel });
import AOS from 'aos';
AOS.init();

import lightGallery from "lightgallery";
import lgZoom from "lightgallery/plugins/zoom";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import "user-select-contain-polyfill";

window.lightGallery = lightGallery;
window.lgZoom = lgZoom;
window.lgThumbnail = lgThumbnail;

lightGallery(document.getElementById('lightgallery'), {
    plugins: [lgZoom, lgThumbnail],
    licenseKey: 'CC788407-0D24-4001-B508-7E7D101480EB',
    speed: 500,
    selector: '.light-gallery-item',
    download: false,
    share: false,
    showCloseIcon: true,
});
